import axios from "axios";
let token = localStorage.getItem("token");
export const login = (values) => {
  return axios
    .post(`${process.env.REACT_APP_ORDERAT}/admin/login`, {
      email: values?.email,
      password: values?.password,
    })
    .then((res) => {
      return res;
    });
};

export const getstorelist = () => {
  let token = localStorage.getItem("token");
  return axios
    .post(
      `${process.env.REACT_APP_ORDERAT}/store/list`,
      {
        zone: process.env.REACT_APP_ZONE,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const getproductlist = (storeId) => {
  return axios
    .post(
      `${process.env.REACT_APP_ORDERAT}/product/list`,
      {
        storeId: storeId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const setstorestatus = (storeId, status, timing) => {
  return axios
    .post(
      `${process.env.REACT_APP_ORDERAT}/store/setStatus`,
      {
        status: status,
        storeId: storeId,
        timing: timing,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const acceptOrder = (storeId, orderId) => {
  //    console.log('storeId',storeId,orderId)
  return axios
    .post(
      `${process.env.REACT_APP_ORDERAT}/order/accept`,
      {
        storeId: storeId,
        orderId: orderId,
        status: "accepted",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const confirmOrderlist = () => {
  return axios
    .post(
      `${process.env.REACT_APP_ORDERAT}/order/list/confirm`,
      {
        storeId: "SUBWAY",
        page: 1,
        pageSize: 10,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const cancelOrder = (storeId, orderId, canceltestarea, cancelreason) => {
  let message;
  if (cancelreason === "other") {
    message = canceltestarea;
  } else {
    message = cancelreason;
  }
  return axios
    .post(
      `${process.env.REACT_APP_ORDERAT}/order/delete`,
      {
        orderId: orderId,
        storeId: storeId,
        message: message,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const setproductstatus = (productId, status) => {
  return axios
    .post(
      `${process.env.REACT_APP_ORDERAT}/product/update/status`,
      {
        productId: productId,
        status: status,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const getorderlist = (size, page, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_ORDERAT}/order/list`,
      {
        page: page,
        pageSize: size,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const placeorder = (data) => {
  // console.log('datadata',data)
  return axios
    .post(
      `${process.env.REACT_APP_APIOTTONOMY_URL}order/v1/placeOrder`,
      {
        orderId: data?.orderId,
        fleetId: "61f7886316bad77abc2f6bb2",
        customerName: "",
        customerMobileNumber: data?.mobileNumber,
        customerEmail: data?.userName,
        orderDetails: "",
        orderValue: "",
        pickupId: "SUBWAY",
        dropId: data?.dropId,
        qrcodePickup: data?.orderId,
        qrcodeDrop: data?.orderId,
        timeout: "180",
        timeoutBeh: "1000",
        deadline: "1000",
        pin: data?.orderId?.substr(-4),
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      //    console.log(' Placeorder response', response)
      return response;
    })
    .catch((error) => {
      console.log("placeorder Error", error);
      return error;
    });
};

export const OpenCabinAPI = (data) => {
  return axios
    .post(
      `${process.env.REACT_APP_APIOTTONOMY_URL}interrupt/v1/openDoorByOrderId`,
      {
        orderId: data.orderId,
        fleetId: "61f7886316bad77abc2f6bb2",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const editstore = (storeId, storeData) => {
  return axios
    .put(
      `${process.env.REACT_APP_ORDERAT}/store/edit`,
      {
        storeId: storeId,
        storeData: storeData,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
