import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

const TotalOrdersChart = (props) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: false,
    },
    scales: {
      x: {
        ticks: {
          fontSize: 10, // Adjust the font size here
        },
      },
    },
  };

  const xAxis = new Set(
    props?.orderlist?.map((item) => {
      // console.log('item',item.storeId,'props.currentStore',props.currentStore)
      if (item.storeId === props.currentStore) {
        return new Date(item.createdAt)
          .toLocaleString("en-US", {
            timeZone: props.currentStoreTimezone,
          })
          .split(",")[0];
      }
      return null;
    })
  );
  const labels = Array.from(xAxis).sort(function (a, b) {
    var c = new Date(a);
    var d = new Date(b);
    return c - d;
  });

  const counts = {};
  props?.orderlist?.map((x) => {
    if (x.storeId === props.currentStore) {
      return (counts[
        new Date(x.createdAt)
          .toLocaleString("en-US", {
            timeZone: props.currentStoreTimezone,
          })
          .split(",")[0]
      ] = counts[
        new Date(x.createdAt)
          .toLocaleString("en-US", {
            timeZone: props.currentStoreTimezone,
          })
          .split(",")[0]
      ]
        ? counts[
            new Date(x.createdAt)
              .toLocaleString("en-US", {
                timeZone: props.currentStoreTimezone,
              })
              .split(",")[0]
          ] +
          parseFloat(x.tax) +
          parseFloat(x.cartTotal)
        : parseFloat(x.tax) + parseFloat(x.cartTotal));
    }

    return null;
  });
  const sortedKeys = Object.keys(counts).sort(function (a, b) {
    var c = new Date(a);
    var d = new Date(b);
    return c - d;
  });

  const sortedObject = {};

  for (const key of sortedKeys) {
    sortedObject[key] = counts[key];
  }
  const data = {
    labels,
    datasets: [
      {
        label: "Total Sales",
        data: Array.from(Object.keys(sortedObject), (k) => sortedObject[k]),
        borderColor: "rgb(0, 0, 255)",
        backgroundColor: "rgba(0, 0, 255, 0.5)",
      },
    ],
  };

  function sumObjectValues(obj) {
    let sum = 0;
    for (let key in obj) {
      // if (typeof obj[key] === "number") {
      sum += obj[key];
      // }
    }

    return sum;
  }
  return (
    <div className="Chart_wrapper">
      <p>
        {" "}
        Total sales -{" "}
        <span>
          {props?.orderlist[0]?.currency !== undefined
            ? props?.orderlist[0]?.currency
            : null}
          {sumObjectValues(counts).toFixed(2)}
        </span>
      </p>
      <Line options={options} data={data} />
    </div>
  );
};

export default TotalOrdersChart;
