import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

const AverageCartChart = (props) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: false,
    },
  };

  const xAxis = new Set(
    props?.orderlist?.map((item) => {
      if (item.storeId === props.currentStore) {
        return new Date(item.createdAt)
          .toLocaleString("en-US", {
            timeZone: props.currentStoreTimezone,
          })
          .split(",")[0];
      }
      return null;
    })
  );
  const labels = Array.from(xAxis).sort(function (a, b) {
    var c = new Date(a);
    var d = new Date(b);
    return c - d;
  });

  const cartTotal = {};
  props?.orderlist?.map((x) => {
    if (x.storeId === props.currentStore) {
      return (cartTotal[
        new Date(x.createdAt)
          .toLocaleString("en-US", {
            timeZone: props.currentStoreTimezone,
          })
          .split(",")[0]
      ] =
        (cartTotal[
          new Date(x.createdAt)
            .toLocaleString("en-US", {
              timeZone: props.currentStoreTimezone,
            })
            .split(",")[0]
        ] || parseFloat(x.cartTotal)) + parseFloat(x.cartTotal));
    }
    return null;
  });

  const counts = {};
  props?.orderlist?.map((x) => {
    if (x.storeId === props.currentStore) {
      return (counts[
        new Date(x.createdAt)
          .toLocaleString("en-US", {
            timeZone: props.currentStoreTimezone,
          })
          .split(",")[0]
      ] =
        (counts[
          new Date(x.createdAt)
            .toLocaleString("en-US", {
              timeZone: props.currentStoreTimezone,
            })
            .split(",")[0]
        ] || 0) + 1);
    }

    return null;
  });
  // console.log("COUNTS", counts);
  const sortedKeys = Object.keys(counts).sort(function (a, b) {
    var c = new Date(a);
    var d = new Date(b);
    return c - d;
  });
  const sortedObject = {};

  for (const key of sortedKeys) {
    sortedObject[key] = counts[key];
  }

  const data = {
    labels,
    datasets: [
      {
        label: "Average Cart",
        data: Array.from(Object.keys(cartTotal), (k) =>
          parseFloat(cartTotal[k] / sortedObject[k])
        ),
        borderColor: "rgb(0, 255, 0)",
        backgroundColor: "rgba(0, 255, 0, 0.5)",
      },
    ],
  };
  function sumObjectValues(obj) {
    let sum = 0;
    obj.forEach((item) => {
      sum += item;
    });

    return sum;
  }
  // console.log( 'carttotal', cartTotal , 'counts',counts)
  return (
    <div className="Chart_wrapper">
      <p>
        {" "}
        Average Cart Values -{" "}
        <span>
          {props?.orderlist[0]?.currency !== undefined &&
            props?.orderlist[0]?.currency}
          {!isNaN(
            (
              sumObjectValues(
                Array.from(Object.keys(cartTotal), (k) =>
                  parseFloat(cartTotal[k] / sortedObject[k])
                )
              ) /
              Array.from(Object.keys(cartTotal), (k) =>
                parseFloat(cartTotal[k] / sortedObject[k])
              ).length
            ).toFixed(2)
          )
            ? (
                sumObjectValues(
                  Array.from(Object.keys(cartTotal), (k) =>
                    parseFloat(cartTotal[k] / sortedObject[k])
                  )
                ) /
                Array.from(Object.keys(cartTotal), (k) =>
                  parseFloat(cartTotal[k] / sortedObject[k])
                ).length
              ).toFixed(2)
            : parseFloat(0).toFixed(2)}{" "}
        </span>
      </p>
      <Line options={options} data={data} />
    </div>
  );
};

export default AverageCartChart;
