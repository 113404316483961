import { Box, Modal } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const NewOrder = ({ open,handleacceptorder ,handleClose,confirmOrder ,confirmarr}) => {
  const navigate = useNavigate();
  const newOrderModalstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 480,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
  };

  return (
    <div>
      <Modal
        open={open}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={newOrderModalstyle}>
          <div className="NewOrder_modal_wrapper">
            <audio
              preload="auto"
              autoPlay="autoplay"
              src={alert}
              disabled={false}
              loop
            ></audio>
            <div className="NewOrder_modal_wrapper_1">
              <img src="" alt="" />
            </div>
            <div className="NewOrder_modal_wrapper_2">
              <h1>New Order</h1>
              <div>
                {/* {console.log('confirmarr',confirmarr)}  */}
                {confirmOrder?.map((item) => {
                  if (item.status === "order confirmed") {
                    return item?.orderData?.map((itm) => {
                      return (
                        <p>
                          {" "}
                          {itm.name} <span> x{itm.quantity} </span>
                        </p>
                      );
                    });
                  }
                })}
              </div>
              <div className="d_flex_center NewOrder_modal_button_div">
                <button
                  onClick={() => handleacceptorder(confirmarr)}
                  className="NewOrder_modal_accept_button"
                >
                  {" "}
                  Accept Order
                </button>
                <button
                  onClick={() => {
                    navigate("/admindashboard");
                    handleClose();
                  }}
                  className="NewOrder_modal_gotoOrder_button"
                >
                  {" "}
                  Go to Order page
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default NewOrder;
