import React from 'react'
import { Button } from '@mui/material'
import { useState } from 'react'
import RightContent from '../RightContent'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { acceptOrder, cancelOrder } from '../../API'
import { showSuccessToast } from '../../components/Toast/configToast';
import { useEffect } from 'react';


function NewOrder(props) {


  const [, setcancelorder] = useState(false)
  const handleOpen = () => setOpen(true);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const [cancelreason, setcancelreason] = useState('Item Unavailable')
  const [canceltestarea, setcanceltextarea] = useState()
  const [acceptorderbutton, setacceptorderbutton] =useState(true)

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 480,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
  };
  const handlecancelOrder = (data) => {
    cancelOrder(data.storeId, data.orderId, canceltestarea, cancelreason).then((res) => {
      props.refreshfunction()
      handleClose()
      // console.log('Cancel Order Response', res)
      showSuccessToast(res.data.message)
      props.setcancel(true)
    }).catch((err) => {
      console.log('Cancel Order Error', err)
    })
  }
  const handlecanclereason = (e) => {
    setcancelreason(e.target.value)
  }

  const handleacceptorder = (data) => {
    // console.log('data',data)
    props.setcancel(false)
    props.setloaderstate(true)
    // placeorder(data).then((res) => {
    //   toastmsg(res.data.message)
    //   props.refreshfunction()
    // })
    //   .catch((err) => {
    //     console.log('Place Order Error', err)
    //   })
    if(acceptorderbutton){
      acceptOrder(data.storeId, data.orderId).then((res) => {
        console.log('Accept Order Response', res)
        props.setcancel(true)
        setTimeout(()=>{ props.setloaderstate(false) },2000)
        setacceptorderbutton(false)
      }).catch((err) => {
        console.log('Accept Order Error', err)
      })
    }

  }
 useEffect(()=>{
  setInterval(()=>{
    setacceptorderbutton(true)
  }, 7000)
 },[])

 const getrobotSpawn=(robotSpawn)=>{
  switch(robotSpawn){
    case 0:
      return 'trying to spawn robot'
    case 1:
      return 'robot will reach pickup soon'
    case 2:
      return 'robot spawn has failed please contact support'    
    default:
      return ''  
  }
 }
  return (
    <div className="AdminDashboard-tabs-rightside-inner-wrapper">
      <div className='AdminDashboard-tabs-rightside-header'>
        <h5 className="rightsideheader-h5">  {props.data.orderId}  <span> , {props.data.storeId}</span> , 
          <span className='p_colorred'> ( {props.robotSpawn && props.robotSpawn })</span>
        </h5>
        <p className='dashboard-tab-new rightsideheader-p'> {props.data.status}</p>
      </div>
      <div className='AdminDashboard-tabs-rightside-content'>
        {/* {console.log('Order Confirmed', props.data)} */}
        <RightContent data={props.data} storelistData={props.storelistData} />
        
      </div>
      <div className="Dashboard-accept-order-button-wrapper">
        <Button className="Dashboard-accept-order-button" onClick={() => handleacceptorder(props.data)}> Accept Order</Button>
        {/* <div className="AdminDashboard-tabs-rightside-content-right-side"> */}
          <Button className='AdminDashboard-tabs-rightside-content-right-side-btn active-btndashboard' onClick={() => {
            setcancelorder(true)
            handleOpen()
            props.setcancel(false)
          }} >  Cancel Order</Button>
          {/* <Button className='AdminDashboard-tabs-rightside-content-right-side-btn' disabled> Summon Robot</Button> */}
          {/* <Button className='AdminDashboard-tabs-rightside-content-right-side-btn' disabled> Open Door</Button> */}
        {/* </div> */}
      </div>
      <Modal
        open={open}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description" >
        <Box sx={style}>
          <div className="NewOrder_modal_wrapper">
            <div className='CancelOrder_Modal_heading'>
              <h1>Cancel Order</h1>
              <div onClick={handleClose} style={{ cursor: 'pointer' }}> <CancelOutlinedIcon sx={{ marginBottom: '0px' }} /><span> Close</span></div>
            </div>
            <div>
              <div className='d_flex_left cancelOrder_Modal_radio_wrapper'>
                <input type="radio" name="cancelorder" value='Item Unavailable' onChange={(e) => handlecanclereason(e)} defaultChecked />
                <p > Item Unavailable</p>
              </div>
              <div className='d_flex_left cancelOrder_Modal_radio_wrapper'>
                <input type="radio" name="cancelorder" value="Unable to service" onChange={(e) => handlecanclereason(e)} />
                <p> Unable to service</p>
              </div>
              <div className='d_flex_left cancelOrder_Modal_radio_wrapper'>
                <input type="radio" name="cancelorder" value="other" onChange={(e) => handlecanclereason(e)} />
                <p> Other</p>
              </div>
            </div>
            {cancelreason === 'other' ? <div>
              <textarea className='Cancel_order_modal_textarea' name="canceltestarea" value={canceltestarea} onChange={(e) => { setcanceltextarea(e.target.value) }}></textarea>
            </div> : null}

            <div className='CancelOrder_Modal_heading_submit_btn'>
              <button onClick={() => handlecancelOrder(props.data)} type="submit"> Submit</button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default NewOrder
