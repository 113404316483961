import React, { useState } from "react";
import AdminHeader from "../admin/AdminHeader";
import { zonelistData } from "../Data/ZoneListData";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { getproductlist, setproductstatus } from "../API";
import { FormControlLabel, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import { showSuccessToast } from "../components/Toast/configToast";

function Items() {
  const [productcheck, setproductcheck] = useState(true);
  const navigate = useNavigate();
  // const [zonesearch,setzonesearch]=useState()
  const [, setdisplayData] = useState();
  const [productlist, setproductlist] = useState();
  let groupedData;
  const [grouparray, setgrouparray] = useState();
  const storeData = useLocation();
  const [refresh, setrefresh] = useState(false);
  useEffect(() => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === "null" ||
      localStorage.getItem("token") === undefined
    ) {
      navigate("/");
    } else {
      setdisplayData(zonelistData);
      // console.log('storeData',storeData)
      getproductlist(storeData.state.storeId)
        .then((res) => {
          // console.log('Product list response',res)
          setproductlist(res.data.message);
        })
        .catch((err) => {
          console.log("Error in Product list", err);
        });
    }
  }, [refresh]);

  useEffect(() => {
    groupedData = productlist?.reduce((accumulator, product) => {
      if (!accumulator[product.category]) {
        accumulator[product.category] = [];
        // Create a new category if it doesn't exist
      }
      accumulator[product.category].push(product); // Add the product to the appropriate category
      return accumulator;
    }, {});
    if (groupedData) {
      setgrouparray(Object.entries(groupedData));
    }
  }, [productlist]);
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const IOSSwitch = styled((props) => (
    <Switch
      checked={productcheck}
      onChange={() => {
        setproductcheck(!productcheck);
      }}
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      color: "#E4261C",
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#29B400",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#EAEAEA" : "#EAEAEA",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  const handleproductstatus = (e, item) => {
    let productstatus;
    if (e.target.checked) {
      productstatus = "0";
    } else {
      productstatus = "1";
    }
    setproductstatus(item._id, productstatus)
      .then((res) => {
        showSuccessToast(res.data.message);
        setrefresh(!refresh);
      })
      .catch((err) => {
        console.log("Error in set product status", err);
      });
  };

  return (
    <div className="DesktopMain-wrapper">
      <AdminHeader stores={true} />
      <div className="container padding-top">
        <div className="row">
          <div className="col-md-12">
            <div className="Zone_page_content_wrapper_main_div">
              <div className="Zone_page_filter_div_wrapper">
                <h3> Items</h3>
              </div>
              <Box className="" sx={{ width: "100%" }}>
                <Box
                  className="Items_page_tabs_wrapper"
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="All" {...a11yProps(0)} />
                    {grouparray?.map((item, index) => {
                      return (
                        <Tab
                          key={`grouparray-${index}`}
                          label={item[0]}
                          {...a11yProps(index + 1)}
                        />
                      );
                    })}
                  </Tabs>
                </Box>

                <TabPanel
                  value={value}
                  id="TablpanelID"
                  index={0}
                  className="Items_content_wrapper_tabpanel"
                >
                  <div className="row">
                    {productlist?.map((item, index) => {
                      return (
                        <div
                          key={`productlist-${index}`}
                          className="col-md-4 Items_single_two"
                        >
                          <div className="Items_content_Individual_item">
                            <div className="Items_content_Individual_item_inner_one">
                              <img
                                className={
                                  item.status === 0 ? "" : "inactive_store"
                                }
                                src={item.image}
                                alt="StoreIcon"
                              />
                            </div>
                            <div className="Items_content_Individual_item_inner_two">
                              <p
                                className={
                                  item.status === 0 ? "" : "inactive_store"
                                }
                              >
                                {" "}
                                {item.en.name}
                              </p>
                              <i
                                className={
                                  item.status === 0 ? "" : "inactive_store"
                                }
                              >
                                {" "}
                                {item.category}
                              </i>
                              <h3
                                className={
                                  item.status === 0 ? "" : "inactive_store"
                                }
                              >
                                {storeData?.state?.currency}{item.price}{" "}
                              </h3>
                            </div>
                            <div> </div>
                            <div className="Items_content_Active_toggle">
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} />}
                                label=""
                                checked={item.status === 1 ? false : true}
                                onChange={(e) => handleproductstatus(e, item)}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </TabPanel>
                {grouparray?.map((item, index) => {
                  return (
                    <div className="row" key={`row-${index}`}>
                      {item[1].map((itm, key) => {
                        return (
                          <div
                            className="col-md-4 Items_single"
                            key={`items-${key}`}
                          >
                            <TabPanel value={value} index={index + 1}>
                              <div className="Items_content_Individual_item">
                                <div className="Items_content_Individual_item_inner_one">
                                  <img
                                    className={
                                      itm.status === 0 ? "" : "inactive_store"
                                    }
                                    src={itm?.image}
                                    alt="StoreIcon"
                                  />
                                </div>
                                <div className="Items_content_Individual_item_inner_two">
                                  <p
                                    className={
                                      itm.status === 0 ? "" : "inactive_store"
                                    }
                                  >
                                    {" "}
                                    {itm?.en.name}
                                  </p>
                                  <i
                                    className={
                                      itm.status === 0 ? "" : "inactive_store"
                                    }
                                  >
                                    {" "}
                                    {itm?.category}
                                  </i>
                                  <h3
                                    className={
                                      itm.status === 0 ? "" : "inactive_store"
                                    }
                                  >
                                     {storeData?.state?.currency}{itm?.price}{" "}
                                  </h3>
                                </div>
                                <div> </div>
                                <div className="Items_content_Active_toggle">
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                    label=""
                                    checked={itm.status === 1 ? false : true}
                                    onChange={(e) =>
                                      handleproductstatus(e, itm)
                                    }
                                  />
                                </div>
                              </div>
                            </TabPanel>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Items;
