import { Checkbox, FormControlLabel, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import AdminHeader from "../admin/AdminHeader";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { editstore, getstorelist, setstorestatus } from "../API";
import {
  showErrorToast,
  showSuccessToast,
} from "../components/Toast/configToast";
function Stores() {
  const [storecheck, setstorecheck] = useState(true);
  const [storelist, setstorelist] = useState();
  const [refresh, setrefresh] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === "null" ||
      localStorage.getItem("token") === undefined
    ) {
      navigate("/");
    }
  }, [navigate]);
  useEffect(() => {
    getstorelist()
      .then((res) => {
        // console.log('Storelist response', res)

        if (res.data.status === 200 || res.data.status === "200") {
          setstorelist(res.data.message);
        } else {
          setstorelist([]);
        }
      })
      .catch((err) => {
        console.log("Error in Store list", err);
      });
  }, [refresh, navigate]);
  const IOSSwitch = styled((props) => (
    <Switch
      checked={storecheck}
      onChange={() => {
        setstorecheck(!storecheck);
      }}
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      color: "#E4261C",
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#29B400",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#EAEAEA" : "#EAEAEA",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const handlestorestatus = (e, item) => {
    let storestatus;
    let today = new Date();

    if (e.target.checked) {
      storestatus = "1";
    } else {
      storestatus = "0";
    }

    setstorestatus(item.storeId, storestatus, today.toISOString())
      .then((res) => {
        console.log("Set Store response", res);
        showSuccessToast(res.data.message);
        setrefresh(!refresh);
      })
      .catch((err) => {
        console.log("Set Store err", err);
      });
  };
  const handleSingleStore = (item) => {
    navigate(`/items/${item.storeId}`, { state: item });
  };

  const handlehidewhenclose = (event, data) => {
    let storeData = {};

    storeData.hideWhenClosed = event.target.checked;
    editstore(data.storeId, storeData)
      .then((res) => {
        showSuccessToast(res.data.message.message);
        console.log(res);
      })
      .catch((err) => {
        showErrorToast(err.message);
        // console.log(err);
      });
  };

  return (
    <>
      <div className="DesktopMain-wrapper">
        <AdminHeader stores={true} />
        <div className="container padding-top">
          <div className="row">
            <div className="col-md-12">
              <div className="Zone_page_content_wrapper_main_div">
                <div className="Zone_page_filter_div_wrapper">
                  <h3> Stores</h3>
                </div>
              </div>
              <div className="Stores_Page_store_list_table">
                {storelist?.length > 0 ? (
                  <table
                    className="Store_page_table"
                    cellPadding="0"
                    cellSpacing="0"
                  >
                    <thead>
                      <tr>
                        <th colSpan="2"> Name</th>
                        <th> Zone </th>
                        <th> Owner</th>
                        <th> </th>
                        <th> </th>
                      </tr>
                    </thead>
                    <tbody>
                      {storelist?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td
                              onClick={() => handleSingleStore(item)}
                              style={{ cursor: "pointer" }}
                            >
                              {" "}
                              <img src={item.image} alt="" />
                            </td>
                            <td
                              onClick={() => handleSingleStore(item)}
                              style={{ cursor: "pointer" }}
                            >
                              <h3>{item.en.name}</h3>
                              <p>{item.description}</p>
                            </td>
                            <td> {item.location} </td>
                            <td style={{ color: "#3CB0CC" }}>
                              <span className="">
                                {" "}
                                {item.storeManager.name
                                  ? item.storeManager.name
                                  : "Not Assigned"}
                              </span>{" "}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} />}
                                label=""
                                checked={item.status === 1 ? true : false}
                                onChange={(e) => handlestorestatus(e, item)}
                              />
                            </td>
                            <td>
                              <Checkbox
                                label="Hide when Close"
                                checked={item.hideWhenClosed}
                                onChange={(event) => {
                                  handlehidewhenclose(event, item);
                                  setrefresh(!refresh);
                                }}
                                sx={{
                                  color: "#3CB0CC",
                                  "&.Mui-checked": {
                                    color: "#3CB0CC",
                                  },
                                }}
                              />
                              Hide when Close
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <p> No Stores available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Stores;
