import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

const TotalOrdersChart = (props) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: false,
    },
  };

  const xAxis = new Set(
    props?.orderlist?.map((item) => {
      if (item.storeId === props.currentStore) {
        return new Date(item.createdAt)
          .toLocaleString("en-US", {
            timeZone: props.currentStoreTimezone,
          })
          .split(",")[0];
      }
      return null;
    })
  );
  const labels = Array.from(xAxis).sort(function (a, b) {
    var c = new Date(a);
    var d = new Date(b);
    return c - d;
  });

  const counts = {};
  props?.orderlist?.map((x) => {
    if (x.storeId === props.currentStore) {
      return (counts[
        new Date(x.createdAt)
          .toLocaleString("en-US", {
            timeZone: props.currentStoreTimezone,
          })
          .split(",")[0]
      ] =
        (counts[
          new Date(x.createdAt)
            .toLocaleString("en-US", {
              timeZone: props.currentStoreTimezone,
            })
            .split(",")[0]
        ] || 0) + 1);
    }

    return null;
  });
  const sortedKeys = Object.keys(counts).sort(function (a, b) {
    var c = new Date(a);
    var d = new Date(b);
    return c - d;
  });
  const sortedObject = {};

  for (const key of sortedKeys) {
    sortedObject[key] = counts[key];
  }

  // console.log(counts, " counts");
  // console.log(sortedObject, " sortedObject");
  const data = {
    labels,
    datasets: [
      {
        label: "Total Orders",
        data: Array.from(Object.keys(sortedObject), (k) => sortedObject[k]),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  return (
    <div className="Chart_wrapper">
      <p>
        Total Orders -{" "}
        <span> {props.orderlist?.length ? props.orderlist?.length : 0}</span>
      </p>
      <Line options={options} data={data} />
    </div>
  );
};

export default TotalOrdersChart;
