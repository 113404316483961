import React, { useCallback, useRef, useState } from "react";
import AdminHeader from "../admin/AdminHeader";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Dropdown from "react-bootstrap/Dropdown";
import Box from "@mui/material/Box";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { useNavigate } from "react-router-dom";
import TabPanelInner from "../admin/TabPanelInner";
import { useEffect } from "react";
import { getstorelist } from "../API";
import Pagination from "@mui/material/Pagination";
import axios from "axios";
import Loader from "../components/Popups/Loader";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function AdminDashboard(props) {
  let token = localStorage.getItem("token");

  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [, setorderaccepted] = useState(false);
  // const[cancelorder,setcancelorder] =useState(false)
  const [orderlist, setorderlist] = useState();
  const [refresh, setrefresh] = useState(false);
  const size = "10";
  const [page, setpage] = useState(1);
  const [showcount, setshowcount] = useState();
  const [Paginationlength, setPaginationlength] = useState(false);
  const [currentpage, setcurrentpage] = useState(1);
  const [storelistData, setStorelistData] = useState([]);
  const [loaderState, setloaderstate] = useState(false);
  const refreshfunction = () => {
    setrefresh(!refresh);
  };
  //  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [, setOpen] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [currentStore, setcurrentStore] = useState();

  useEffect(() => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === "null" ||
      localStorage.getItem("token") === undefined
    ) {
      navigate("/");
      console.log("token", token);
    }
  }, [navigate]);

  useEffect(() => {
    getstorelist()
      .then((res) => {
        // console.log('response storelist',res)
        if (res.data.status === 200 || res.data.status === "200") {
          setStorelistData(res.data.message);
          setcurrentStore(res.data?.message[0]?.storeId);
        } else {
          setStorelistData([]);
        }
      })
      .catch((err) => {
        console.log("Error in Store list", err);
      });
  }, []);
  useEffect(() => {
    setorderaccepted(true);
    setrefresh(!refresh);
  }, []);

  const updateState = useCallback(async () => {
    return axios
      .post(
        `${process.env.REACT_APP_ORDERAT}/order/list`,
        {
          storeId: currentStore,
          page: page,
          pageSize: size,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // setTotalOrders(res.data.data.count)
        // setorderlist(res.data.data.order)
        // console.log('res',res)
        setorderlist(
          res.data.order?.filter((item) => item.status !== "booked")
        );
        // console.log('res.data.order',res)
        if (res.data?.count > size) {
          setPaginationlength(true);
          // setPaginationlength(Paginationlength => Paginationlength+1)
        }
        if (res.data.count % 1 === 0) {
          setshowcount(Math.floor(res.data.count / 10) + 1);
          // console.log('res.data.data.count%1',res.data.data.count%1)
          // console.log('showcount',showcount)
        } else {
          setshowcount(res.data.order?.length / 10);
          setPaginationlength(false);
        }
      })
      .catch((err) => {
        console.log("Error in Order list", err);
      });
  }, [currentpage, size, currentStore]);

  const useIntervalAsync = (callbackFunction, intervalInMs) => {
    const timeout = useRef();

    const apiCall = useCallback(async () => {
      await callbackFunction();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = window.setTimeout(apiCall, intervalInMs);
    }, [callbackFunction, intervalInMs]);

    useEffect(() => {
      apiCall();
      return () => {
        clearTimeout(timeout.current);
      };
    }, [apiCall]);
  };

  useIntervalAsync(updateState, 5000);

  const handleChangetwo = (event, value) => {
    setpage(value);
    setcurrentpage(value);
  };

  const getStatusclass = (orderStatus) => {
    switch (orderStatus) {
      case "InProgress":
        return "dashboard-tab-inprogress";
      case "booked":
        return "";
      case "order delivered":
        return "dashboard-tab-delivered";
      case "order cancelled":
        return "dashboard-tab-cancel";
      case "New":
        return "dashboard-tab-new-new";
      case "order confirmed":
        return "dashboard-tab-new-new";
      default:
        return "dashboard-tab-inprogress";
    }
  };

  return (
    <>
      <div className="DesktopMain-wrapper">
        {loaderState ? (
          <>
            {" "}
            <Loader handleClose={handleClose} />
          </>
        ) : null}

        <AdminHeader order={true} storelistData={storelistData} />
        <div className="container padding-top">
          <div className="row">
            <div className="col-md-4" style={{ textAlign: "left" }}>
              <div className="AdminDashboard-tabs-left-side">
                <>
                  <div className="AdminDashboard-tabs-left-side_heading">
                    <h1>
                      {" "}
                      Stores <ChevronRightOutlinedIcon />
                      {storelistData?.length > 1 ? (
                        <span>
                          <Dropdown
                            onSelect={(eventKey) => {
                              const selectedItem = storelistData.find(
                                (item) => item.storeId === eventKey
                              );
                              setcurrentStore(selectedItem.storeId);
                            }}
                            id="Store_dropdown_select"
                          >
                            <Dropdown.Toggle id="dropdown-basic">
                              {currentStore}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="custom-dropdown-menu">
                              {storelistData?.map((item) => (
                                <Dropdown.Item
                                  key={item.storeId}
                                  eventKey={item.storeId}
                                >
                                  {item.storeId}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </span>
                      ) : (
                        <span> {currentStore}</span>
                      )}
                    </h1>
                  </div>
                </>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ overflow: "unset" }}
                  indicatorColor={""}
                  className="Single-tab-wrapper-main"
                >
                  {/* {console.log('currentStore',currentStore,'orderlist',orderlist)} */}
                  {orderlist?.map((item, index) => {
                    if (item.storeId === currentStore) {
                      return (
                        <Tab
                          key={index}
                          label={
                            <div className="dashboard-order-list-wrapper">
                              <div className="dashboard-order-list-wrapper-inner-div-one">
                                <h5> {item.orderId}</h5>
                                <p className={getStatusclass(item.status)}>
                                  {" "}
                                  {item.status}
                                </p>
                              </div>
                              <div className="dashboard-order-list-wrapper-inner-div-two">
                                <p className="dashboard-order-list-wrapper-inner-div-two-p-one">
                                  {" "}
                                  {item.storeId}{" "}
                                </p>
                                {/* <div className="Dashboard-page-seperator-line"></div> */}
                                <p className="dashboard-order-list-wrapper-inner-div-two-p-two">
                                  {" "}
                                  {item.dropId}
                                </p>
                              </div>
                            </div>
                          }
                          {...a11yProps(index)}
                          className="Inner-tab"
                        />
                      );
                    }
                  })}
                </Tabs>
                {Paginationlength ? (
                  <Pagination
                    className="PaginationWrapper"
                    count={showcount}
                    page={page}
                    onChange={handleChangetwo}
                    sx={{ width: "100%" }}
                  />
                ) : null}
              </div>
            </div>
            <div className="col-md-8" style={{ textAlign: "left" }}>
              <div className="AdminDashboard-tabs-right-side">
                {orderlist?.map((item, index) => {
                  return (
                    <TabPanel
                      key={index}
                      value={value >= orderlist?.length ? 0 : value}
                      index={index}
                    >
                      <TabPanelInner
                        storelistData={storelistData}
                        setloaderstate={setloaderstate}
                        loaderState={loaderState}
                        data={item}
                        refreshfunction={refreshfunction}
                        setcancel={props.setcancel}
                        canel={props.canel}
                      />
                    </TabPanel>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="Circular_progress">
            {" "}
            {orderlist?.length < 0 ||
            orderlist === "Order Not Found" ||
            orderlist === undefined ||
            orderlist === null ? (
              <>
                <h3> No orders to show</h3>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminDashboard;
